function RefSeqComponent() {
  'use strict';

  this.geneTracks = [];

  this.$onChanges = (changedObjects) => {
    if(changedObjects.tracks && changedObjects.tracks.currentValue) {
      const tracks = changedObjects.tracks.currentValue;

      for (const track of tracks) {
        if(track.type == 'gene') {
          this.geneTracks.push(track);
        }
      }

      console.info(this.geneTracks);
    }
  };

}

angular.module('sq.jobs.results.search.refSeq.component', [])
.component('sqRefSeq', {
  bindings: {
    tracks: '<',
    data: '<',
  }, // isolate scope
  templateUrl: 'jobs/results/search/refSeq/jobs.results.search.refSeq.tpl.html',
  controller: RefSeqComponent,
});